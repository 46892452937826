<template>
    <MainLayout>
        <div class="container">
            <div class="go-back-container">
                <button class="blue-link" @click="goBack">BACK</button>
            </div>
            <div class="page-title-container">
                <CsvUploader
                    inputId="upload-new-row"
                    :disabled="loading || isUploading"
                    @onUpload="handleUploadNewRow"
                ></CsvUploader>

                <div
                    :class="{
                        'page-title': true,
                        'loading-placeholder': loading && !title,
                    }"
                    style="width: 200px; height: 45px"
                >
                    {{ title }}
                </div>
                <div class="page-title-action">
                    <button
                        class="button yellow-outline"
                        :disabled="loading || isUploading"
                        @click="handleUploadButtonClick"
                    >
                        {{ isExporting ? "Uploading..." : "Upload New Row" }}
                    </button>
                    <button
                        class="button yellow-outline"
                        :disabled="loading || isExporting"
                        @click="handleExport"
                    >
                        {{ isExporting ? "Downloading..." : "Download Report" }}
                    </button>
                    <button
                        class="button yellow with-icon"
                        :disabled="loading"
                        @click="handleScan"
                    >
                        <font-awesome-icon icon="barcode"></font-awesome-icon>
                        {{ isSubmitting ? "Scanning..." : "Scan" }}
                    </button>
                </div>
            </div>

            <div class="action-container">
                <form @submit.prevent="search">
                    <!-- <input class="search-input" type="search" v-model="searchTerm" placeholder="Search" /> -->
                    <input
                        class="search-input"
                        type="text"
                        v-model="searchTerm"
                        placeholder="Search"
                        @keydown="handleSearchKeyDown"
                    />
                </form>
                <button
                    v-if="isShowSearchResult"
                    class="red-link"
                    @click="clearSearch"
                >
                    Clear
                </button>
            </div>

            <div v-if="searchResultTerm && !loading" class="search-description">
                {{
                    total > 0
                        ? `Found ${total} result${total > 1 ? "s" : ""}`
                        : "No result found"
                }}
                for "{{ searchResultTerm }}".
            </div>

            <div v-if="!isShowSearchResult" class="sub-nav">
                <button
                    :class="{
                        'sub-nav-button': true,
                        active: currentSubNav.name === subNav.name,
                    }"
                    @click="handleSubNav(subNav)"
                    v-for="subNav in subNavs"
                    :key="subNav.name"
                    :disabled="loading"
                >
                    {{ subNav.name }}
                    <span
                        v-if="
                            !subNav.skipBubble &&
                            bubble &&
                            bubble[subNav.bubbleKey]
                        "
                        class="sub-nav-bubble"
                    >
                        {{ bubble[subNav.bubbleKey] }}
                    </span>
                </button>
            </div>
            <div class="table-container">
                <div v-if="!loading">
                    <template v-if="data && data.length">
                        <div class="table-scollable">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Bag ID</th>
                                        <th>Parcel ID</th>
                                        <th>Owned by</th>
                                        <th>Scanned At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(d, i) in data" :key="d.id">
                                        <td>{{ page * LIMIT + i + 1 }}</td>
                                        <td>{{ d.bag_id }}</td>
                                        <td>{{ d.parcel_id }}</td>
                                        <td>{{ d.owned_by }}</td>
                                        <td>
                                            {{
                                                d.scanned_at
                                                    ? formatAMPM(d.scanned_at)
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            <form
                                                @submit.prevent="
                                                    handleEdit(d.id)
                                                "
                                                class="table-action-container"
                                            >
                                                <select
                                                    class="table-select-input"
                                                    :id="`select-${d.id}`"
                                                    :value="userSelect[d.id]"
                                                    @change="handleUserSelect"
                                                >
                                                    <option
                                                        v-for="selection in availableSelection"
                                                        :key="selection.name"
                                                    >
                                                        {{ selection.name }}
                                                    </option>
                                                </select>
                                                <button
                                                    class="square-button blue"
                                                    @click="handleEdit(d.id)"
                                                    :disabled="
                                                        userSelect[d.id] ===
                                                            d.status ||
                                                        isEditing
                                                    "
                                                >
                                                    <!-- <i v-if="isEditing" class="fa-solid fa-spinner simple-spin"></i> -->
                                                    <font-awesome-icon
                                                        icon="paper-plane"
                                                    ></font-awesome-icon>
                                                </button>
                                            </form>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination-container">
                            <div class="pagination-detail">
                                {{ page * LIMIT + 1 }} -
                                {{ Math.min(total, (page + 1) * LIMIT) }} of
                                {{ total }}
                            </div>
                            <div class="pagination-button-container">
                                <button
                                    class="pagination-button"
                                    :disabled="page === 0"
                                    @click="pagination.goStart"
                                >
                                    {{ "<<" }}
                                </button>
                                <button
                                    class="pagination-button"
                                    :disabled="page === 0"
                                    @click="pagination.goPrevious"
                                >
                                    {{ "<" }}
                                </button>
                                <button
                                    class="pagination-button"
                                    :disabled="
                                        page === Math.floor(total / LIMIT)
                                    "
                                    @click="pagination.goNext"
                                >
                                    {{ ">" }}
                                </button>
                                <button
                                    class="pagination-button"
                                    :disabled="
                                        page === Math.floor(total / LIMIT)
                                    "
                                    @click="pagination.goEnd"
                                >
                                    {{ ">>" }}
                                </button>
                            </div>
                        </div>
                    </template>
                    <div v-else class="table-no-data">
                        <font-awesome-icon
                            icon="circle-question"
                        ></font-awesome-icon>
                        NO DATA
                    </div>
                </div>
                <div v-else class="loading-animation">
                    <font-awesome-icon icon="spinner"></font-awesome-icon>
                </div>
            </div>
        </div>
        <ModalLayout
            :isOpen="isScanModalOpen"
            @onClose="closeScanModal"
            @onRoot="setModalRef"
        >
            <ScanModal
                @onClose="closeScanModal"
                @onSubmit="handleScanSubmit"
                :isSubmitting="isSubmitting"
            ></ScanModal>
        </ModalLayout>
        <ToastLayout :toastList="toastList"></ToastLayout>
    </MainLayout>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from "vue"
import MainLayout from "../components/layout/MainLayout.vue"
import ModalLayout from "../components/layout/ModalLayout.vue"
import ToastLayout from "../components/layout/ToastLayout.vue"
import ScanModal from "../components/modal/ScanModal.vue"
import { getApi, postApi, formatAMPM } from "../util/dodoo"
import { useRouter, useRoute } from "vue-router"
import CsvUploader from "../components/CsvUploader.vue"

// const route = useRoute();

const LIMIT = 20
const route = useRoute()
const router = useRouter()

const subNavs = [
    { name: "To Scan", key: "pending", bubbleKey: "to_scan" },
    {
        name: "Complete",
        key: "scanned,shortlanded",
        bubbleKey: "complete",
        skipBubble: true,
    },
    { name: "All", key: "pending,scanned,shortlanded", bubbleKey: "all" },
]

const availableSelection = [
    { name: "Pending", key: "pending" },
    { name: "Scanned", key: "scanned" },
    { name: "Shortlanded", key: "shortlanded" },
]

const isScanModalOpen = ref(false)
const isSubmitting = ref(false)
const isExporting = ref(false)
const isEditing = ref(false)
const isUploading = ref(false)

const data = ref()
const total = ref()
const bubble = ref()
const title = ref()
const userSelect = ref()
const keyTracker = ref([])

const modalRef = ref()

const searchTerm = ref()
const toastList = ref([])
const page = ref(0)
const loading = ref(true)
const currentSubNav = ref(subNavs[0])
const isShowSearchResult = ref(false)
const searchResultTerm = ref()

const setModalRef = (r) => {
    modalRef.value = r
}

const closeScanModal = () => {
    const wrapper = modalRef.value.value
    if (wrapper) {
        wrapper.classList.add("closing")
        wrapper.addEventListener(
            "animationend",
            () => {
                isScanModalOpen.value = false
            },
            { once: true }
        )
    } else {
        isScanModalOpen.value = false
    }
}

const handleSubNav = (subN) => {
    page.value = 0

    if (searchTerm.value || subN.key !== currentSubNav.value.key) {
        searchTerm.value = undefined
        currentSubNav.value = subN
        fetchData()
    }
}

const search = () => {
    if (loading.value) {
        return
    }

    page.value = 0

    // Handle empty search
    if (!searchTerm.value || !searchTerm.value.trim()) {
        clearSearch()
        return
    }

    // Handle search value
    searchResultTerm.value = searchTerm.value?.trim()
    isShowSearchResult.value = true
    fetchData()
}

const handleSearchKeyDown = (e) => {
    switch (e.key) {
        case "Escape":
            if (
                isShowSearchResult.value ||
                searchResultTerm.value ||
                searchTerm.value
            ) {
                clearSearch()
            } else {
                fetchData()
            }
            break

        default:
            break
    }
}

const clearSearch = () => {
    isShowSearchResult.value = false
    searchTerm.value = ""
    searchResultTerm.value = null
    page.value = 0
    fetchData()
}

const pagination = {
    goStart: () => {
        page.value = 0
        fetchData()
    },
    goPrevious: () => {
        page.value = Math.max(page.value - 1, 0)
        fetchData()
    },
    goNext: () => {
        page.value = Math.min(page.value + 1, Math.floor(total.value / LIMIT))
        fetchData()
    },
    goEnd: () => {
        page.value = Math.floor(total.value / LIMIT)
        fetchData()
    },
}

const toast = (msg, type = "success") => {
    const id = Math.floor(Math.random() * 1000)
    const timer = setTimeout(onRemoveToast, 3000, id)

    toastList.value.push({ id, msg, type, timer })
    if (toastList.value.length > 5) {
        toastList.value.pop()
    }
}

const onRemoveToast = (id) => {
    toastList.value = toastList.value.filter((t) => t.id !== id)
}

const handleUploadButtonClick = () => {
    document.querySelector("input#upload-new-row").click()
}

const handleUploadNewRow = async (e, cb) => {
    isUploading.value = true
    const filesToUpload = e.files ? e.files[0] : e.target.files[0]
    const fd = new FormData()
    fd.append("doc", filesToUpload)
    fd.append("job_id", route.params.parcel_id)
    const uploadFileResponse = await postApi("/scan/jobs/upload", fd)

    isUploading.value = false

    if (uploadFileResponse.job_id) {
        toast("Successfully uploaded")
        clearSearch()
    } else {
        toast(
            uploadFileResponse?.error ||
                "Something went wrong on uploading file",
            "error"
        )
        cb && cb()
    }
}

const handleUserSelect = (e) => {
    userSelect.value[e.target.id.split("-")[1]] = e.target.value
}

const handleEdit = async (id) => {
    isEditing.value = true
    const fd = new FormData()
    fd.append("id", id)
    fd.append("status", userSelect.value[id])
    const editResponse = await postApi(
        `scan/jobs/update_consignment?page=${page.value}&status=${currentSubNav.value.key}`,
        fd
    )
    if (editResponse.updated_at) {
        data.value = editResponse.consignments
        total.value = editResponse.consignments_total
        title.value = editResponse.container_no
        bubble.value = editResponse.bubble
        userSelect.value = editResponse.consignments.reduce(
            (a, b) => ({ ...a, [b.id]: b.status }),
            {}
        )
        // data.value = data.value.map(d=>(d.id === id ? {...d, status: userSelect.value[id]} : d));
        toast("Successfully edited")
    } else {
        toast(editResponse.error || "Error on editing", "error")
    }

    isEditing.value = false
}

const handleExport = async () => {
    isExporting.value = true

    const exportResponse = await getApi(
        `scan/jobs/report/${route.params.parcel_id}`
    )
    if (!exportResponse || exportResponse.error) {
        toast(exportResponse.error || "Error on editing", "error")
    } else {
        const aLink = document.createElement("a")
        aLink.download = `${title.value}_export.csv`
        aLink.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(
            exportResponse
        )}`

        const event = new MouseEvent("click")
        aLink.dispatchEvent(event)

        toast("Successfully exported")
    }
    isExporting.value = false
}

const handleScan = () => {
    isScanModalOpen.value = true
}

const handleScanSubmit = async (barcode, callback) => {
    isSubmitting.value = true
    const fd = new FormData()
    fd.append("barcode", barcode?.trim ? barcode.trim() : barcode)
    fd.append("job_id", route.params.parcel_id)
    const { scanned_result, error, ...scanResponse } = await postApi(
        `/scan/jobs/scan?page=${page.value}&status=${currentSubNav.value.key}`,
        fd
    )

    isSubmitting.value = false

    if (!error) {
        // toast("Successully scanned");
        callback({
            success: true,
            target: scanned_result.owned_by || "-",
            barcode,
        })

        data.value = scanResponse.consignments
        total.value = scanResponse.consignments_total
        title.value = scanResponse.container_no
        bubble.value = scanResponse.bubble
        userSelect.value = scanResponse.consignments.reduce(
            (a, b) => ({ ...a, [b.id]: b.status }),
            {}
        )
    } else {
        // toast(barcodeResponse?scanned_result..error || "Failed to scan", "error");
        // callback({success: false, target: barcodeResponse.scanned_result.owned_by, error: barcodeResponse?scanned_result..error?.indexOf("scanned") > -1 ? "Already Scanned!" : "Invalid Barcode!"});
        callback({
            success: false,
            target: scanResponse.owned_by,
            barcode,
            error: error || "Something went wrong!",
        })
    }
}

// const handleDelete = async (cb) => {
//     isDeleting.value = true;

//     const deleteResposne = await deleteApi(`/scan/jobs/${deletingTarget.value}`);
//     if(deleteResposne.success){
//         data.value =data.value.filter(d=>d.id !== deletingTarget.value);
//         toast("Successfully deleted");
//         cb();
//     }else{
//         toast(deleteResposne.error || "Error on deleting", "error");
//     }

//     deletingTarget.value = null;
//     isDeleting.value = false;
// }

const checkIfBarcode = (arr = []) => {
    const cleanArr = arr.map((a) => a.timeStamp).reverse()
    const l = Math.ceil(arr.length / 2)
    for (let i = 0; i < l; i++) {
        if (i === 0) {
            continue
        }
        const diff = cleanArr[i - 1] - cleanArr[i]
        const diff_perc = diff / cleanArr[i - 1]
        if (diff > 50 || diff_perc > 0.1) {
            return false
        }
    }
    return true
}

const handleTriggerScanWarning = (e) => {
    if (isScanModalOpen.value) {
        return
    }
    if (e.isComposing) {
        return
    }
    const { key, code, timeStamp } = e
    const isDigit = code.indexOf("Digit") > -1 || code.indexOf("Num") > -1
    const isAlphabet = code.indexOf("Key") > -1
    const isPossibleScannerKey = code.indexOf("Shift") > -1

    if (key === "Enter") {
        if (keyTracker.value.length > 5 && checkIfBarcode(keyTracker.value)) {
            toast(
                "Please click the'Scan' button before you start scanning",
                "error"
            )
        }
        keyTracker.value = []
    } else if (!(isDigit || isAlphabet || isPossibleScannerKey)) {
        keyTracker.value = []
    } else {
        if (keyTracker.value.length > 20) {
            keyTracker.value.shift()
        }
        keyTracker.value.push({ key, timeStamp })
    }
}

const goBack = () => {
    router.push("/")
}

const generateApiUrl = () => {
    const base = `/scan/jobs/detail/${route.params.parcel_id}/${currentSubNav.value.key}`
    const query = {
        search: searchTerm.value?.trim() || undefined,
        page: page.value,
    }
    let queryString = []
    for (let q in query) {
        if (query[q] !== undefined) {
            queryString.push(
                `${encodeURIComponent(q)}=${encodeURIComponent(query[q])}`
            )
        }
    }
    return `${base}?${queryString.join("&")}`
}

const fetchData = async () => {
    loading.value = true
    const url = generateApiUrl()
    const result = await getApi(url)
    if (result.unauth) {
        return router.replace({ path: "/login", query: { status: "unauth" } })
    }
    if (result.error) {
        toast(result.error || "Error fetching data", "error")
        router.push("/")
        return
    }
    title.value = result.container_no
    total.value = result.consignments_total
    data.value = result.consignments
    bubble.value = result.bubble
    userSelect.value = result.consignments.reduce(
        (a, b) => ({ ...a, [b.id]: b.status }),
        {}
    )
    loading.value = false
}

onMounted(() => {
    document.body.addEventListener("keydown", handleTriggerScanWarning)
    fetchData()
})

onBeforeUnmount(() => {
    document.body.removeEventListener("keydown", handleTriggerScanWarning)
})
</script>

<style scoped>
.upload-overlay {
    display: none;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    margin-top: var(--top-nav-height);
    padding: 32px;
}
.upload-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 3px dashed white;
    color: white;
}
.upload-text {
    font-size: 40px;
    margin-top: 24px;
}
</style>
