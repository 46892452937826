<template>
    <div class="modal-container">
        <div class="scan-input">
            <div class="modal-confirmation-message">
                {{ props.message || "Are you sure?" }}
            </div>
            <div class="scan-action">
                <button class="button yellow-outline" @click="handleClose">
                    Cancel
                </button>
                <button
                    class="button yellow"
                    @click="handleConfirm"
                    :disabled="isSubmitting"
                >
                    Confirm
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue"
const props = defineProps({
    message: String,
    isSubmitting: Boolean,
})

const emit = defineEmits(["onSubmit", "onClose"])

const handleConfirm = () => {
    emit("onSubmit", handleClose)
}

const handleClose = () => {
    emit("onClose")
}
</script>

<style scoped></style>
