<template>
    <MainLayout>
        <div class="container">
            <div class="page-title-container">
                <div class="page-title">Job List</div>
                <button
                    class="button yellow"
                    @click="handleUploadButtonClick"
                    :disabled="loading || isUploading"
                >
                    {{ isUploading ? "Uploading..." : "Upload Excel" }}
                </button>
                <CsvUploader
                    inputId="upload-csv"
                    :disabled="loading || isUploading"
                    @onUpload="handleUploadCSV"
                ></CsvUploader>
                <!-- <input type="file" id="upload-csv" name="csv" @change="handleUploadCSV" accept=".csv" hidden="hidden"/> -->
            </div>

            <div class="action-container">
                <form @submit.prevent="search">
                    <!-- <input class="search-input" type="search" v-model="searchTerm" placeholder="Search" /> -->
                    <input
                        class="search-input"
                        type="text"
                        v-model="searchTerm"
                        placeholder="Search"
                        @keydown="handleSearchKeyDown"
                    />
                </form>
                <button
                    v-if="isShowSearchResult"
                    class="red-link"
                    @click="clearSearch"
                >
                    Clear
                </button>
            </div>
            <div v-if="searchResultTerm && !loading" class="search-description">
                {{
                    total > 0
                        ? `Found ${total} result${total > 1 ? "s" : ""}`
                        : "No result found"
                }}
                for "{{ searchResultTerm }}".
            </div>

            <!-- <div class="exp-sub-nav">
                <div class="exp-sub-nav-button-container">
                    <button :class="{'sub-nav-button': true, 'active': currentSubNav.name === subNav.name}" @click="handleSubNav(subNav)" v-for="subNav in subNavs" :key="subNav.name" :disabled="loading" >
                    {{subNav.name}}
                    <span v-if="!subNav.skipBubble && bubble" class="sub-nav-bubble">
                        {{bubble[subNav.bubbleKey]}}
                    </span>
                </button>
                </div>
            </div> -->

            <div v-if="!isShowSearchResult" class="sub-nav">
                <button
                    :class="{
                        'sub-nav-button': true,
                        active: currentSubNav.name === subNav.name,
                    }"
                    @click="handleSubNav(subNav)"
                    v-for="subNav in subNavs"
                    :key="subNav.name"
                    :disabled="loading"
                >
                    {{ subNav.name }}
                    <span
                        v-if="
                            !subNav.skipBubble &&
                            bubble &&
                            bubble[subNav.bubbleKey]
                        "
                        class="sub-nav-bubble"
                    >
                        {{ bubble[subNav.bubbleKey] }}
                    </span>
                </button>
            </div>

            <div class="table-container">
                <div v-if="!loading">
                    <template v-if="data && data.length">
                        <div class="table-scollable">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Container No.</th>
                                        <th>Row</th>
                                        <th>Handled</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(d, i) in data" :key="d.id">
                                        <td>{{ page * LIMIT + i + 1 }}</td>
                                        <td>{{ d.container_no }}</td>
                                        <td>
                                            {{ d.total_consignments || "0" }}
                                        </td>
                                        <td>{{ d.total_handled || "0" }}</td>
                                        <td>
                                            <div class="table-action-container">
                                                <button
                                                    :disabled="
                                                        currentSubNav.name !==
                                                        'Upcoming'
                                                    "
                                                    class="square-button red"
                                                    @click="del(d.id)"
                                                >
                                                    <font-awesome-icon
                                                        icon="trash-can"
                                                    ></font-awesome-icon>
                                                </button>
                                                <button
                                                    class="square-button blue"
                                                    @click="view(d.id)"
                                                >
                                                    <font-awesome-icon
                                                        icon="eye"
                                                    ></font-awesome-icon>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination-container">
                            <div class="pagination-detail">
                                {{ page * LIMIT + 1 }} -
                                {{ Math.min(total, (page + 1) * LIMIT) }} of
                                {{ total }}
                            </div>
                            <div class="pagination-button-container">
                                <button
                                    class="pagination-button"
                                    :disabled="page === 0"
                                    @click="pagination.goStart"
                                >
                                    {{ "<<" }}
                                </button>
                                <button
                                    class="pagination-button"
                                    :disabled="page === 0"
                                    @click="pagination.goPrevious"
                                >
                                    {{ "<" }}
                                </button>
                                <button
                                    class="pagination-button"
                                    :disabled="
                                        page ===
                                        Math.max(
                                            Math.floor((total - 1) / LIMIT),
                                            0
                                        )
                                    "
                                    @click="pagination.goNext"
                                >
                                    {{ ">" }}
                                </button>
                                <button
                                    class="pagination-button"
                                    :disabled="
                                        page ===
                                        Math.max(
                                            Math.floor((total - 1) / LIMIT),
                                            0
                                        )
                                    "
                                    @click="pagination.goEnd"
                                >
                                    {{ ">>" }}
                                </button>
                            </div>
                        </div>
                    </template>
                    <div v-else class="table-no-data">
                        <font-awesome-icon
                            icon="circle-question"
                        ></font-awesome-icon>
                        NO DATA
                    </div>
                </div>
                <div v-else class="loading-animation">
                    <font-awesome-icon icon="spinner"></font-awesome-icon>
                </div>
            </div>
        </div>

        <ModalLayout
            :isOpen="isConfirmationOpen"
            @onClose="closeConfirmationModal"
            @onRoot="setModalRef"
        >
            <ConfirmModal
                @onClose="closeConfirmationModal"
                @onSubmit="handleDelete"
                :isSubmitting="isDeleting"
            ></ConfirmModal>
        </ModalLayout>
        <ToastLayout :toastList="toastList"></ToastLayout>
    </MainLayout>
</template>

<script setup>
import { onMounted, ref } from "vue"
import MainLayout from "../components/layout/MainLayout.vue"
import ModalLayout from "../components/layout/ModalLayout.vue"
import ToastLayout from "../components/layout/ToastLayout.vue"
import ConfirmModal from "../components/modal/ConfirmModal.vue"
import { deleteApi, getApi, postApi } from "../util/dodoo"
import { useRouter } from "vue-router"
import CsvUploader from "../components/CsvUploader.vue"
// const route = useRoute();

const LIMIT = 20
const router = useRouter()

const subNavs = [
    { name: "Upcoming", key: "upcoming", bubbleKey: "total_upcoming" },
    { name: "In Progress", key: "in-progress", bubbleKey: "total_in_progress" },
    { name: "Done", key: "done", bubbleKey: "total_done", skipBubble: true },
]

const isConfirmationOpen = ref(false)

const data = ref()
const total = ref()
const bubble = ref()

const modalRef = ref()

const searchTerm = ref()
const toastList = ref([])
const page = ref(0)
const deletingTarget = ref()
const isDeleting = ref(false)
const isUploading = ref(false)
const loading = ref(true)
const currentSubNav = ref(subNavs[0])
const isShowSearchResult = ref(false)
const searchResultTerm = ref()

const setModalRef = (r) => {
    modalRef.value = r
}

const closeConfirmationModal = () => {
    const wrapper = modalRef.value.value
    if (wrapper) {
        wrapper.classList.add("closing")
        wrapper.addEventListener(
            "animationend",
            () => {
                isConfirmationOpen.value = false
            },
            { once: true }
        )
    } else {
        isConfirmationOpen.value = false
    }
}

const handleSubNav = (subN) => {
    page.value = 0

    if (searchTerm.value || subN.key !== currentSubNav.value.key) {
        searchTerm.value = ""
        currentSubNav.value = subN
        fetchData()
    }
}

const search = () => {
    if (loading.value) {
        return
    }

    page.value = 0

    // Handle empty search
    if (!searchTerm.value || !searchTerm.value.trim()) {
        clearSearch()
        return
    }

    // Handle search value
    searchResultTerm.value = searchTerm.value?.trim()
    isShowSearchResult.value = true
    fetchData()
}

const handleSearchKeyDown = (e) => {
    switch (e.key) {
        case "Escape":
            if (
                isShowSearchResult.value ||
                searchResultTerm.value ||
                searchTerm.value
            ) {
                clearSearch()
            } else {
                fetchData()
            }
            break

        default:
            break
    }
}

const clearSearch = () => {
    isShowSearchResult.value = false
    searchTerm.value = ""
    searchResultTerm.value = null
    page.value = 0
    fetchData()
}

const pagination = {
    goStart: () => {
        page.value = 0
        fetchData()
    },
    goPrevious: () => {
        page.value = Math.max(page.value - 1, 0)
        fetchData()
    },
    goNext: () => {
        page.value = Math.min(page.value + 1, Math.floor(total.value / LIMIT))
        fetchData()
    },
    goEnd: () => {
        page.value = Math.floor(total.value / LIMIT)
        fetchData()
    },
}

const toast = (msg, type = "success") => {
    const id = Math.floor(Math.random() * 1000)
    const timer = setTimeout(onRemoveToast, 3000, id)

    toastList.value.push({ id, msg, type, timer })
    if (toastList.value.length > 5) {
        toastList.value.pop()
    }
}

const onRemoveToast = (id) => {
    toastList.value = toastList.value.filter((t) => t.id !== id)
}

const del = (id) => {
    deletingTarget.value = id
    isConfirmationOpen.value = true
}

const handleDelete = async (cb) => {
    isDeleting.value = true

    const deleteResposne = await deleteApi(`/scan/jobs/${deletingTarget.value}`)
    if (deleteResposne.success) {
        data.value = data.value.filter((d) => d.id !== deletingTarget.value)
        total.value = data.value.length
        toast("Successfully deleted")
        cb()
    } else {
        toast(deleteResposne.error || "Error on deleting", "error")
    }

    deletingTarget.value = null
    isDeleting.value = false
}

const view = (job_id) => {
    router.push(`/${job_id}`)
}

const handleUploadButtonClick = () => {
    document.querySelector("input#upload-csv").click()
}

const handleUploadCSV = async (e, cb) => {
    isUploading.value = true
    const filesToUpload = e.files ? e.files[0] : e.target.files[0]
    const fd = new FormData()
    fd.append("doc", filesToUpload)
    const uploadFileResponse = await postApi("/scan/jobs/upload", fd)

    isUploading.value = false

    if (uploadFileResponse.job_id) {
        toast("Successfully uploaded")
        router.push(`/${uploadFileResponse.job_id}`)
    } else {
        toast(
            uploadFileResponse?.error ||
                "Something went wrong on uploading file",
            "error"
        )
        cb && cb()
    }
}

const generateApiUrl = () => {
    const base = `/scan/jobs/list/${currentSubNav.value.key}`
    const query = {
        search: searchTerm.value?.trim() || undefined,
        page: page.value,
    }
    let queryString = []
    for (let q in query) {
        if (query[q] !== undefined) {
            queryString.push(
                `${encodeURIComponent(q)}=${encodeURIComponent(query[q])}`
            )
        }
    }
    return `${base}?${queryString.join("&")}`
}

const fetchData = async () => {
    loading.value = true
    const url = generateApiUrl()
    const result = await getApi(url)
    if (result.unauth) {
        console.log("GO LOGIN")
        router.push({ path: "/login", query: { status: "unauth" } })
        return
    }
    if (!result || result.error) {
        toast(result.error || "Error fetching data ", "error")
        return
    }
    bubble.value = result.bubble
    total.value = result.total
    data.value = result.data
    loading.value = false
}

onMounted(() => {
    fetchData()
})
</script>

<style scoped>
.upload-overlay {
    display: none;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    margin-top: var(--top-nav-height);
    padding: 32px;
}
.upload-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 3px dashed white;
    color: white;
}
.upload-text {
    font-size: 40px;
    margin-top: 24px;
}
</style>
