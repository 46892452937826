<template>
    <div class="modal-container">
        <div v-if="feedback" class="scan-feedback">
            <div v-if="feedback.success" class="feedback-heading">Owned By</div>
            <div v-if="feedback.success" class="feedback-owner">
                {{ feedback.target }}
            </div>
            <div v-if="!feedback.success" class="feedback-icon">
                <font-awesome-icon
                    icon="circle-exclamation"
                ></font-awesome-icon>
            </div>
            <div v-if="!feedback.success" class="feedback-heading">
                {{ feedback.error }}
            </div>
            <div
                v-if="!feedback.success && feedback.target"
                class="feedback-paragraph"
            >
                {{ `Owned by ${feedback.target}` }}
            </div>
            <div v-if="feedback.barcode" class="feedback-barcode">
                {{ feedback.barcode }}
            </div>
        </div>
        <form
            class="scan-input"
            @submit.prevent="handleSubmit"
            autocomplete="off"
        >
            <label>Please scan barcode</label>
            <input
                type="text"
                name="barcode"
                id="scan-barcode-input"
                v-model="barcode"
                :disabled="isSubmitting"
                @focus="handleFocus"
                @blur="handleBlur"
            />
            <div
                v-if="!isInFocus && !props.isSubmitting"
                class="scanner-input-warning"
            >
                <font-awesome-icon
                    icon="circle-exclamation"
                ></font-awesome-icon>
                Please focus on the field before scanning!
            </div>
            <div class="scan-action">
                <button
                    class="button yellow-outline"
                    type="button"
                    @click="handleClose"
                >
                    Cancel
                </button>
                <button
                    class="button yellow"
                    :disabled="props.isSubmitting || !barcode.trim().length"
                >
                    Confirm
                </button>
            </div>
        </form>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue"
const props = defineProps({
    isSubmitting: Boolean,
})

const emit = defineEmits(["onSubmit", "onClose"])

const barcode = ref("")
const isSubmitting = ref(false)
const isInFocus = ref()
const feedback = ref()

const handleSubmit = () => {
    if (barcode.value.trim().length) {
        emit("onSubmit", barcode.value, handleObtainFeedback)
    }
}

const handleClose = () => {
    emit("onClose")
    feedback.value = null
}

const handleFocus = () => {
    isInFocus.value = true
}
const handleBlur = () => {
    isInFocus.value = false
}

const handleObtainFeedback = (d) => {
    barcode.value = ""
    feedback.value = d
    document.querySelector("#scan-barcode-input")?.focus()
}

onMounted(() => {
    document.querySelector("#scan-barcode-input")?.focus()
})
</script>

<style></style>
