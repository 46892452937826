<template>
    <div class="csv-upload-wrapper" id="csv-drop-area">
        <div class="csv-upload-content">
            <div class="csv-icon">
                <font-awesome-icon icon="file-excel"></font-awesome-icon>
            </div>
            <div>Drop your file here to upload</div>
        </div>
    </div>
    <input
        type="file"
        :id="inputId"
        name="csv"
        @input="handleUploadCSV"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden="hidden"
        :disabled="props.disabled"
    />
</template>

<script setup>
import { onMounted, defineProps, defineEmits, onBeforeUnmount } from "vue"

const props = defineProps({
    disabled: Boolean,
    inputId: String,
    overlayName: String,
})
const emit = defineEmits(["onUpload"])

const handleUploadCSV = (e) => {
    emit("onUpload", e, () => {
        const i = document.querySelector(`#${props.inputId}`)
        if (i) {
            i.value = null
        }
    })
}

const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    document.querySelector("#csv-drop-area").classList.add("show")
}
const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    document.querySelector("#csv-drop-area").classList.add("show")
}
const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    document.querySelector("#csv-drop-area").classList.remove("show")
}
const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    document.querySelector("#csv-drop-area").classList.remove("show")

    handleUploadCSV(e.dataTransfer)
}

onMounted(() => {
    document.addEventListener("dragenter", handleDragEnter)
    document.addEventListener("dragover", handleDragOver)
    document.addEventListener("dragleave", handleDragLeave)
    document.addEventListener("drop", handleDrop)
})

onBeforeUnmount(() => {
    document.removeEventListener("dragenter", handleDragEnter)
    document.removeEventListener("dragover", handleDragOver)
    document.removeEventListener("dragleave", handleDragLeave)
    document.removeEventListener("drop", handleDrop)
})
</script>

<style scoped>
.csv-upload-wrapper {
    display: none;
    position: fixed;
    margin-top: 0;
    top: var(--top-nav-height);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    padding: 32px;
    z-index: 1;
    pointer-events: none;
}
.csv-upload-wrapper.show {
    display: initial;
}

.csv-upload-content {
    border-radius: 8px;
    border: 3px dashed white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    gap: 16px;
    font-size: 24px;
}
.csv-icon {
    font-size: 40px;
}
</style>
