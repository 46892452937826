// import { postApi } from "../util/dodoo";
import { createRouter, createWebHistory } from "vue-router"
import Login from "../views/Login.vue"
import JobList from "../views/JobList.vue"
import ParcelList from "../views/ParcelList.vue"

const routes = [
    {
        path: "/",
        name: "job",
        component: JobList,
        beforeEnter: async (to, from, next) => {
            // const authResponse = await postApi("/api/auth/current_user");
            // if(!authResponse || authResponse.error || authResponse.unauth) {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                    // query: {status: "unauth"}
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        beforeEnter: async (to, from, next) => {
            // const authResponse = await postApi("/api/auth/current_user");
            // if(!authResponse || authResponse.unauth || authResponse.error) {
            if (!localStorage.getItem("access_token")) {
                next()
            } else {
                next({
                    path: "/",
                    replace: true,
                })
            }
        },
    },
    {
        path: "/:parcel_id",
        name: "parcel",
        component: ParcelList,
        beforeEnter: async (to, from, next) => {
            // const authResponse = await postApi("/api/auth/current_user");
            // if(!authResponse || authResponse.error || authResponse.unauth) {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/login",
    },
]

const router = createRouter({
    history: createWebHistory("/"),
    routes,
})

export default router
