import { createApp } from "vue"
import App from "./App.vue"
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import {
    faEye,
    faArrowRightFromBracket,
    faPaperPlane,
    faSpinner,
    faTrashCan,
    faFileCsv,
    faCircleExclamation,
    faCircleQuestion,
    faBarcode,
    faFileExcel,
} from "@fortawesome/free-solid-svg-icons"
// import "./registerServiceWorker";
import router from "./router"

library.add(
    faEye,
    faArrowRightFromBracket,
    faPaperPlane,
    faSpinner,
    faTrashCan,
    faFileCsv,
    faCircleExclamation,
    faCircleQuestion,
    faBarcode,
    faFileExcel
)

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .mount("#app")

// - Handled count on Job List
// - Bubble on 'Upcoming' & "In progress"
// - Upload new row endpoint?
// - Bubble on 'To Scan' & 'All';
// -- To Scan: pending
// -- Complete: scanned/shortlanded
// -- All: pending/scanned/shortlanded
