const isJSONStringifyAble = (body) => {
    try {
        JSON.stringify(body)
        return true
    } catch (error) {
        return false
    }
}

export const downloadCSV = (urlData, fileName = "default.csv") => {
    const aLink = document.createElement("a")
    const evt = document.createEvent("HTMLEvents")
    evt.initEvent("click")
    aLink.download = fileName
    aLink.href = urlData
    aLink.dispatchEvent(evt)
}

export const formatAMPM = (d) => {
    const date = new Date(d)

    const da = date.toLocaleString().slice(0, 10).replaceAll("/", "-")

    let hours = date.getHours()
    let minutes = date.getMinutes()
    const ampm = hours >= 12 ? "PM" : "AM"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes
    const strTime = hours + ":" + minutes + " " + ampm
    return da + " " + strTime
}

export const postApi = (url, body, headers = {}) => {
    if (body instanceof FormData) {
        headers = { ...headers }
    } else {
        if (isJSONStringifyAble(body)) {
            body = JSON.stringify(body)
            headers["Content-Type"] = "Application/JSON"
        }
    }
    return f("POST", url, body, headers)
}

export const getApi = (url) => {
    return f("GET", url)
}

export const deleteApi = (url) => {
    return f("DELETE", url)
}

const f = async (method, url, body, headers = {}) => {
    if (!["GET", "POST", "PUT", "DELETE"].includes(method.toUpperCase())) {
        throw new Error(`Invalid fetch method: ${method}`)
    }
    const response = await fetch(url, {
        method,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            ...headers,
        },
        body,
    })

    try {
        if (response.status >= 400 && response.status < 500) {
            console.log(response.status, response.statusText)
            return { unauth: true }
        }

        if (
            response.headers
                .get("Content-Type")
                .toLowerCase()
                .indexOf("application/csv") > -1
        ) {
            // console.log("CSV FOUND");
            return response.text()
        }

        if (
            response.headers
                .get("Content-Type")
                .toLowerCase()
                .indexOf("text/html") > -1
        ) {
            return { error: "Something went wrong" }
        }
        const result = await response.json()
        return result
    } catch (error) {
        // alert("Something went wrong");
        console.warn(error)
        return error({ error })
    }
}
