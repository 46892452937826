<template>
    <router-view />
</template>

<script setup></script>

<style>
:root {
    --color-kt-blue: #0b3459;
    --color-kt-yellow: #ffb400;
    --color-kt-red: red;
    --color-kt-green: #28a745;

    --color-toast-success: var(--color-kt-blue);
    --color-toast-error: var(--color-kt-red);

    --top-nav-height: 60px;
    --container-size: 80%;
}
#app,
#modal {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    /* color: #2c3e50; */
}
.wrapper {
    position: relative;
}
nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

* {
    margin: 0;
    box-sizing: border-box;
}
.container {
    width: var(--container-size);
    margin: 0 auto;
    padding: 24px 0;
}
.container > div + div {
    margin-top: 24px;
}
.go-back-container {
    text-align: left;
}
.page-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    text-align: left;
}
.page-title {
    font-size: 30px;
    color: var(--color-kt-blue);
}
.page-title-action {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.page-title-action {
    row-gap: 16px;
    column-gap: 16px;
}
button {
    cursor: pointer;
    border: none;
    transition: 80ms;
    user-select: none;
}
button:disabled {
    cursor: not-allowed;
}
.button {
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    height: 40px;
    min-width: 120px;
    background-color: transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    white-space: nowrap;
}
.button.yellow {
    color: white;
    background-color: var(--color-kt-yellow);
    border-color: var(--color-kt-yellow);
}
.button.yellow:disabled {
    color: white;
    background-color: #ddd;
    border-color: #ddd;
}
.button.yellow-outline {
    color: var(--color-kt-yellow);
    background-color: transparent;
    border-color: var(--color-kt-yellow);
}
.button.yellow-outline:disabled {
    color: #ddd;
    border-color: #ddd;
}
.button.with-icon {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
}
.blue-link {
    height: fit-content;
    color: var(--color-kt-blue);
    background-color: transparent;
    border-bottom: 1px solid var(--color-kt-blue);
    padding: 0;
    text-transform: uppercase;
    font-size: 14px;
}
.red-link {
    height: fit-content;
    color: var(--color-kt-red);
    background-color: transparent;
    border-bottom: 1px solid var(--color-kt-red);
    padding: 0;
    text-transform: uppercase;
    font-size: 14px;
}
.square-button {
    flex-shrink: 0;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
}
.square-button.red {
    color: white;
    background-color: var(--color-kt-red);
    border-color: var(--color-kt-red);
}
.square-button.blue {
    color: white;
    background-color: var(--color-kt-blue);
    border-color: var(--color-kt-blue);
}
.square-button:disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #979797;
}
.action-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}
.search-description {
    text-align: left;
    font-size: 14px;
    color: #666;
    margin-top: 10px !important;
}
.sub-nav {
    display: flex;
    border-bottom: 1px solid #eee;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
}
.sub-nav::-webkit-scrollbar {
    display: none;
}
.exp-sub-nav {
    max-width: 100%;
    overflow: hidden;
    position: relative;
}
.exp-sub-nav::before,
.exp-sub-nav::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100px;
    top: 0;
    z-index: 1;
}
.exp-sub-nav::before {
    background-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        white 80%,
        white 50%
    );
    left: -1px;
}
.exp-sub-nav::after {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        white 80%,
        white 50%
    );
    right: -1px;
}
.exp-sub-nav-button-container {
    display: flex;
    overflow-x: scroll;
}
.sub-nav-button {
    font-size: 17px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 16px;
    height: 40px;
    border-radius: 0;
    border-width: 1px;
    border-width: 0;
    border-color: transparent;
    color: #979797;
    background-color: transparent;
    white-space: nowrap;
}
.sub-nav-button:first-child {
    border-top-left-radius: 4px;
}
.sub-nav-button:last-child {
    border-top-right-radius: 4px;
}
.sub-nav-button.active {
    font-weight: bold;
    color: var(--color-kt-yellow);
}
.sub-nav-button.active::after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: var(--color-kt-yellow);
    position: absolute;
    left: 0;
    bottom: -1px;
}
.sub-nav-bubble {
    border-radius: 4px;
    background-color: var(--color-kt-yellow);
    color: white;
    padding: 4px;
    font-size: 12px;
    min-width: 20px;
}
.table-container {
    /* overflow-x: auto; */
    padding-bottom: 24px;
}
.table-scollable {
    overflow-x: auto;
}
.table {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;
    font-size: 15px;
}
.table th {
    height: 50px;
    padding: 0 12px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid var(--color-kt-blue);
    white-space: nowrap;
}
.table td {
    height: 100px;
    padding: 0 12px;
    white-space: nowrap;
}
.table tbody tr:nth-child(odd) {
    background-color: #eee;
}
.table tbody {
    border-bottom: 2px solid #eee;
}

.table-action-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.table-action-container > * + * {
    margin-left: 16px;
}
select.table-select-input {
    height: 40px;
    padding: 4px 12px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: #979797;
    font-size: 17px;
    cursor: pointer;
}
.pagination-container {
    margin-top: 24px;
    text-align: center;
}
.pagination-container > * + * {
    margin-top: 8px;
}
.pagination-detail {
    font-size: 15px;
}
.pagination-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 25px;
    color: white;
    border-radius: 0;
    background-color: #aaa;
}
.pagination-button:hover {
    background-color: var(--color-kt-yellow);
}

.pagination-button:disabled {
    background-color: #ccc;
}
.pagination-button-container > button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.pagination-button-container > button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

input[type="text"],
input[type="search"],
input[type="url"],
input[type="email"],
input[type="password"] {
    height: 40px;
    padding: 4px 12px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: #979797;
    font-size: 17px;
}

input[type="search"] {
    -webkit-appearance: none;
}

input:disabled {
    cursor: not-allowed;
    color: #ddd;
    border-color: #ddd;
}

.loading-animation {
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin: 80px auto;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: #979797;
}
.simple-spin {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.table-no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 80px 0;
    font-weight: bold;
    color: #979797;
    font-size: 28px;
}
.table-no-data svg {
    font-size: 32px;
}

.loading-placeholder {
    cursor: none;
    background: linear-gradient(0.25turn, transparent, #fff, transparent),
        linear-gradient(#ddd, #ddd),
        radial-gradient(38px circle at 19px 19px, #ddd 50%, transparent 51%),
        linear-gradient(#ddd, #ddd);
    background-repeat: no-repeat;
    background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
}

.modal-container {
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.modal-confirmation-message {
    font-weight: bold;
    font-size: 24px;
}
.scan-feedback,
.scan-input {
    padding: 40px 0;
    margin: 0 40px;
}
.scan-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.scan-feedback + form {
    border-top: 1px solid #ccc;
}
.feedback-owner {
    font-size: 36px;
    font-weight: bolder;
}
.feedback-barcode {
    font-weight: 500;
    font-size: 17px;
}
.feedback-icon {
    font-size: 72px;
    color: var(--color-kt-red);
}
.feedback-heading {
    font-size: 24px;
    font-weight: bold;
}
.feedback-paragraph {
    font-size: 17px;
}
.scan-input label {
    display: block;
    font-weight: bold;
    font-size: 15px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 16px;
}
.scan-input input {
    min-width: 280px;
}
.scan-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    gap: 16px;
}
.scanner-input-warning {
    margin-top: 8px;
    font-size: 13px;
    text-align: left;
    color: var(--color-kt-red);
}
.closing .scanner-input-warning {
    display: none;
}

@keyframes loading {
    to {
        background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        transform: rotate(360deg);
        opacity: 1;
    }
}
@keyframes falldown {
    from {
        transform: translateY(-80px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes flyup {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-80px);
        opacity: 0;
    }
}

@media screen and (min-width: 600px) {
    :root {
        --container-size: 80%;
    }

    .sub-nav-button:hover {
        background-color: #eee;
    }
}
@media screen and (min-width: 905px) {
    :root {
        --container-size: 840px;
    }
}
@media screen and (min-width: 1240px) {
    :root {
        --container-size: 80%;
    }
}
@media screen and (min-width: 1440px) {
    :root {
        --container-size: 1040px;
    }
}
</style>
